<div [ngClass]='rtlLocale'>
    <div class="tt-content-container">
        <div class="container tt-banner-copy-container">
            <form class="dropdown-form" [formGroup]="dropDownForm">
                <div>
                    <div class="selectModelDiv">
                        <span class="col-sm-12 col-md-12 col-lg-12 selectModel pl-0">
                            {{'index.get_latest_software' | translate}}
                        </span>
                        <div class="dropdown">
                            <div class="selectionDiv">
                                <div class="vehicleInfo-style" [ngClass]="{'set-width': rtlLocale==='rtl'}" *ngFor="let vehicleModel of vehicleInfo; let i = index">
                                    <div *ngIf="i==0" style="width: 510px;">
                                        <div class="btn-group bootstrap-select seriesNbr open"
                                            [ngClass]="{'dropup':scrollDown<100}">
                                            <input type="button" [class.error]="submitted && f['firstDropDown'].errors"
                                                value="{{(dropDownForm.value.firstDropDown ? dropDownForm.value.firstDropDownVal : 'index.firstDropDown' | translate)}}"
                                                (click)="openBrandDropDown()" (blur)="closeDropDown()"
                                                class="btn dropdown-toggle btn-default drop-down-design"
                                                [ngClass]="{'invalid': submitted && f['firstDropDown'].invalid}">
                                            <span class="caret caret-design"
                                                [ngClass]="{'invalid': submitted && f['firstDropDown'].invalid, 'caret-design-left': rtlLocale==='non-rtl','caret-design-right': rtlLocale==='rtl'}"></span>
                                            <div *ngIf="brandDropDownToggle" class="dropdown-menu open downdown-style">
                                                <ul class="dropdown-menu inner ">
                                                    <li [ngClass]="{'selected': dropDownForm.value.firstDropDown === null}">
                                                        <a (mousedown)="fetchCarModelData(null, null)">{{'index.firstDropDown'
                                                            | translate}}</a>
                                                    </li>
                                                    <li *ngFor="let brand of firstDorpDownValue" [ngClass]="{'selected': dropDownForm.value.firstDropDown === brand.ID}">
                                                        <a
                                                            (mousedown)="fetchCarModelData(brand.ID, brand.NAME)">{{brand?.NAME}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style="width: 450px;">
                                    <button name="search" id="search" class="commomButtonPrimary"
                                        (click)="fetchPackageData()">
                                        {{'index.search' | translate}}
                                    </button>
                                </div>

                                <div id="dialog" title=""></div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <app-iveco-my-downloads [package]="package" [formDataDropDown]="formDataDropDown"></app-iveco-my-downloads>
    </div>
</div>