import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { TealiumService } from 'src/app/services/tealium.service';

@Component({
  selector: 'app-iveco-download-popup',
  templateUrl: './iveco-download-popup.component.html',
  styleUrls: ['./iveco-download-popup.component.less']
})
export class IvecoDownloadPopupComponent implements OnInit {

  @Input() downloadUrlData: any;
  @Input() popupDisplayData: any;
  @Input() formDataDropDown: any;
  modalToggle = false;
  downloadMapUrls = [];
  downloadLicenseUrls = [];
  @Output() closePopup = new EventEmitter<boolean>();
  oemName!: string;
  rtlLocale = '';

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
    private tealiumService: TealiumService
  ) {
    if (this.translate.currentLang === 'iveco/ar' || this.translate.currentLang === 'iveco/he_IL') {
      this.rtlLocale = 'rtl';
    } else {
      this.rtlLocale = 'non-rtl';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.translate.currentLang === 'iveco/ar' || this.translate.currentLang === 'iveco/he_IL') {
        this.rtlLocale = 'rtl';
      } else {
        this.rtlLocale = 'non-rtl';
      }
    });
  }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
    for (const x of this.downloadUrlData) {
      this.downloadMapUrls = x.URL_DESC.split(';');
    }
    this.openModal();
  }

  openModal() {
    this.modalToggle = true;
  }

  cancel() {
    this.modalToggle = false;
    this.closePopup.emit(this.modalToggle);
    this.tealiumService.trackEvent({
      event_name: 'navshop_click',
      click_text: 'click_on_cancel_button',
      map_name: this.popupDisplayData.mapName,
      map_version: this.popupDisplayData.mapVersion,
      car_brand: this.formDataDropDown.car_brand
    });
  }

  ngOnDestroy(): void {
    this.downloadMapUrls = [];
    this.downloadLicenseUrls = [];
  }

  addUndeScoreInsteadOfBlankSpace(regionName: string) {
    return regionName.split(" ").join("_");
  }

  downloadAndTrackProgress(link: string, type: string) {
    this.tealiumService.trackEvent({
      event_name: 'navshop_click',
      click_text: `click_on_${type}_link`,
      download_link: link,
      map_name: this.popupDisplayData.mapName,
      map_version: this.popupDisplayData.mapVersion,
      car_brand: this.formDataDropDown.car_brand
    });
    this.modalToggle = false;
    this.closePopup.emit(this.modalToggle);
  }

}
